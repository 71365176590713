.modal{
  animation:unfoldIn .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
}


@keyframes unfoldIn {
  0% {
    opacity: 0;
    transform:scaleY(.005) scaleX(0);
  }
  50% {
    transform:scaleY(.005) scaleX(1);
  }
  100% {
    transform:scaleY(1) scaleX(1);
  }
}

